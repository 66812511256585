<template>
    
<div id="app">
    <router-view />
</div>
</template>
<style>
 #app{
    background-color: white;
 }
</style>
<script>
export default {data() {
    return {
      logoutTimer: null,
    };
  },mounted() {
    if (this.$router.currentRoute.path !== '/login') {
      window.addEventListener('beforeunload', this.handleTabClose);
    
    // Set a flag in sessionStorage when the page loads
    sessionStorage.setItem('isReloading', 'true');
    }
    
  },
  created() {
    if (this.$router.currentRoute.path === '/login') {
       this.resetLogoutTimer();
    // Event listeners for user activity
    document.addEventListener('mousemove', this.resetLogoutTimer);
    document.addEventListener('keydown', this.resetLogoutTimer);
    // Add any other events that count as user activity
    }
   
  },
  beforeDestroy() {
    if (this.$router.currentRoute.path === '/login') {
      // Clean up event listeners and timer
    document.removeEventListener('mousemove', this.resetLogoutTimer);
    document.removeEventListener('keydown', this.resetLogoutTimer);
    window.removeEventListener('beforeunload', this.handleTabClose);
    this.clearLogoutTimer();
    localStorage.clear();
    }
    
  },
  methods: {
    handleTabClose(event) {
      // Check if sessionStorage is still set, which means it's a reload, not a close
      if (sessionStorage.getItem('isReloading')) {
        sessionStorage.removeItem('isReloading'); // Clear for the next load
      } else {
        // The sessionStorage is not set, so the tab/window is being closed
        localStorage.clear();
      }
    },
    clearLogoutTimer() {
      clearTimeout(this.logoutTimer);
    },
    resetLogoutTimer() {
      // Clear existing timer
      this.clearLogoutTimer();

      // Set a new logout timer
      this.logoutTimer = setTimeout(() => {
        // Clear localStorage or perform logout action
        localStorage.clear();

        // Redirect to login or home page, or inform the user
        this.$router.push('/login');
      }, 5 * 60 * 1000); // 5 minutes
    },
  },
};

</script>